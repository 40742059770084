/**
 * USER_TYPE
 */
export const USER_TYPE = {
    /** 平台账号 */
    TYPE_0: 0, 

    /** 清洗点 */
    TYPE_1: 1, 

    /** 渠道 */
    TYPE_2: 2, 
    
    /** 代理 */
    TYPE_3: 3 // 
}

export default USER_TYPE;

export const getUserTypeName = (type) => {
  switch (type){
    case USER_TYPE.TYPE_0:
      return '平台账号'
      break;
    case USER_TYPE.TYPE_1:
      return '清洗点'
      break;
    case USER_TYPE.TYPE_2:
      return '渠道'
      break;
    case USER_TYPE.TYPE_3:
      return '代理'
      break;
    default:{
      return `其他[${type}]`
    }
  }
}