import { defineStore } from "pinia";
import { store } from "@/store";
import { localStorage } from "@/utils/storage";
import { getUserInfo, login as loginApi, logout } from "@/api/login";
import { resetRouter } from "@/router";
import { ElMessage, ElLoading } from "element-plus";

const getDefaultState = () => {
  return {
    token: localStorage.get('token'),
    nickname: '',
    avatar: '',
    roles: [],
    perms: []
  }
}


/**
                         * 
                            adminCode: "" 
                            adminId: 1  
                            companyId: 1 
                            companyName: "仓前清洗服务点"
                            createTime: 0
                            creatorId: 0
                            mobile: ""
                            operatorId: 0 
                            operatorTime: 0
                            password: "$2a$10$xfYtrASQHR/j3JV6XSPj..s0gS9AyjdeSQ1LaMNjryk.7jUEDRt76"
                            realName: "admin"
                            roleId: 1
                            status: 1
                            type: 0
                            userName: "admin"
                         */

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    logoTime: localStorage.get('logo_time') || '',
    token: localStorage.get('token') || '',
    nickname: '',
    avatar: '',
    roles: [],
    perms: [],
    adminCode: '',
    adminId: 0,
    companyId: 0,
    companyName: '',
    createTime: 0,
    creatorId: 0,
    mobile: '',
    operatorId: 0,
    operatorTime: 0,
    password: "",
    realName: "", //admin
    roleId: 1,
    isSaturate: 1,
    status: 1,
    type: 0,
    userName: "", //admin
  }),
  actions: {
    async RESET_STATE () {
      this.$reset()
    },
    /**
     * 用户登录请求
     * @param userInfo 登录用户信息
     *  username: 用户名
     *  password: 密码
     *  code: 验证码
     *  uuid: 匹配正确验证码的 key
     */
    login (userInfo) {
      const { username, password, code, uuid } = userInfo
      return new Promise((resolve, reject) => {
        loginApi(
          {
            username: username.trim(),
            password: password,
            grant_type: 'password', // 'captcha',
            // code: code,
            // uuid: uuid
          }
        ).then(response => {
          /**
           * {
              "access_token": "8f2397f7-a08c-4de9-8b1a-5f0d38888f85",
              "token_type": "bearer",
              "refresh_token": "6b282002-120b-47f4-af57-1974b7024f86",
              "expires_in": 36195,
              "scope": "web"
              }
           */


          const { access_token, token_type, refresh_token } = response
          const accessToken = token_type + " " + access_token
          const logoTime = new Date().getTime();
          localStorage.set("ms_username", username);
          localStorage.set("token", accessToken);
          localStorage.set("refresh_token", refresh_token);
          localStorage.set("logo_time", logoTime);
          localStorage.set("req_order_new_time", logoTime);
          this.logoTime = logoTime;
          this.token = accessToken
          resolve(access_token)
        }).catch(error => {
          ElMessage.error("登录失败");
          console.log('登录失败0：', error);
          reject(error)
        })
      })
    },
    /**
     *  获取用户信息（昵称、头像、角色集合、权限集合）
     */
    getUserInfo () {

      return new Promise(((resolve, reject) => {
        const username = localStorage.get("ms_username");
        getUserInfo({ userName: username }).then(response => {

          const { data } = response
          if (!data) {
            return reject('Verification failed, please Login again.')
          }

          this.avatar = data.avatar
          this.roles = [data.roleId];
          this.perms = data.perms || []
          this.isSaturate = data.isSaturate || 0
          this.nickname = data.companyName
          this.companyName = data.companyName
          this.adminCode = data.adminCode
          this.adminId = data.adminId
          this.companyId = data.companyId
          this.createTime = data.createTime
          this.creatorId = data.creatorId
          this.mobile = data.mobile
          this.operatorId = data.operatorId
          this.operatorTime = data.operatorTime
          this.password = data.password
          this.realName = data.realName
          this.roleId = data.roleId
          this.status = data.status
          this.type = data.type
          this.userName = data.userName
          resolve(data)
        }).catch(error => {
          console.log('getUserInfo===:', error);
          reject(error)
        })
      })
      )
    },

    /**
     *  注销
     */
    logout () {
      return new Promise(((resolve, reject) => {
        logout().then(() => {
          console.log('=====111: localStorage.remove(token)');
          localStorage.remove('token')
          this.RESET_STATE()
          resetRouter()
          resolve(null)
        }).catch(error => {
          reject(error)
        })
      }))
    },

    /**
     * 清除 Token
     */
    resetToken () {
      return new Promise(resolve => {
        localStorage.remove('token')
        this.RESET_STATE()
        resolve(null)
      })
    }
  }
})

export function useUserStoreHook () {
  return useUserStore(store);
}

