import request from "@/utils/request";
import requestOld from "@/utils/requestOld";


/**
 * 登录
 * @param data.username  'admin'
 * @param data.password '000000'
 * @param data.grant_type 'password'  'captcha'
 * 
   // code: code,
   // uuid: uuid
 */
export function login(data) {
    return request({
        url: '/auth/oauth/token',
        method:'post',
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': 'Basic d2ViOjEyMzQ1Ng==' // 'Basic bWFsbC1hZG1pbi13ZWI6MTIzNDU2' // 客户端信息Base64明文：mall-admin-web:123456
        }
    })

    /**
     * {
        "access_token": "8f2397f7-a08c-4de9-8b1a-5f0d38888f85",
        "token_type": "bearer",
        "refresh_token": "6b282002-120b-47f4-af57-1974b7024f86",
        "expires_in": 36195,
        "scope": "web"
        }
     */
}

/**
 * 登录成功后获取用户信息（包括用户头像、权限列表等）
 */
export function getUserInfo({userName}) {
    return request({
        url: '/api-web/v1/admin/login/info',
        method: 'post',
        data: {
          userName: userName
        }
    })
}

/**
 * 注销
 */
export function logout() {
    return requestOld({
        url: '/oauth/logout',
        method: 'delete'
    })
}

/**
 * 获取图片验证码
 */
export function getCaptcha() {
    return requestOld({
        url: '/captcha?t='+(new Date()).getTime().toString(),
        method: 'get'
    })
}