import request from '@/utils/request'
import requestOld from '@/utils/requestOld'



/**
 * 获取菜单列表
 *
 * @param queryParams
 */
 export function reqMenuList(data) {
  return request({
      url: '/api-web/v1/menu/role/list',
      method: 'post',
      data: data
  })
}

// ****************************************************************



/**
 * 获取路由列表
 */
export function listRoutes() {
    return requestOld({
        url: '/api/v2/menus/route',
        method: 'get'
    })
}

/**
 * 获取(表格)菜单列表
 *
 * @param queryParams
 */
export function listTableMenus(queryParams) {
    return requestOld({
        url: '/api/v1/menus/table',
        method: 'get',
        params: queryParams
    })
}

/**
 * 获取(下拉)菜单列表
 */
export function listSelectMenus() {
    return requestOld({
        url: '/api/v1/menus/select',
        method: 'get'
    })
}

/**
 * 获取(树形下拉)菜单列表
 */
export function listTreeSelectMenus() {
    return requestOld({
        url: '/api/v1/menus/tree_select',
        method: 'get'
    })
}


/**
 * 获取菜单详情
 * @param id
 */
export function getMenuDetail(id) {
    return requestOld({
        url: '/api/v1/menus/' + id,
        method: 'get'
    })
}

/**
 * 添加菜单
 *
 * @param data
 */
export function addMenu(data) {
    return requestOld({
        url: '/api/v1/menus',
        method: 'post',
        data: data
    })
}

/**
 * 修改菜单
 *
 * @param id
 * @param data
 */
export function updateMenu(id, data) {
    return requestOld({
        url: '/api/v1/menus/' + id,
        method: 'put',
        data: data
    })
}

/**
 * 批量删除菜单
 *
 * @param ids 菜单ID，多个以英文逗号(,)分割
 */
export function deleteMenus(ids) {
    return requestOld({
        url: '/api/v1/menus/' + ids,
        method: 'delete'
    })
}
