
export function getNotification(){
  var Notification = window.Notification || window.mozNotification || window.webkitNotification;
  return Notification;
}

export function requestPermission(){
  var Notification = window.Notification || window.mozNotification || window.webkitNotification;
  if (Notification) {
    return new Promise(function(resolve, reject){  
      Notification.requestPermission(function(permission) {
        console.log('Notification permission status:', permission);
        if (permission === 'granted') {
          resolve(true);
        } else {
          reject(false);
        }
      });
    })
    // Notification.requestPermission(function (status) {
    //   console.log('Notification permission status:', status);
    //   callback && callback(status);
    // })
  }
  return false;
}

export function showNotification(title, options) {
  var Notification = window.Notification || window.mozNotification || window.webkitNotification;
  if (Notification) {
    Notification.requestPermission(function (status) {
      // status默认值
      // 'default' 表示拒绝， 
      // 'denied' 表示用户不想要通知，
      // 'granted' 表示用户同意启用通知
      if (status != "granted") return;
      var tag = "sds" + Math.random();
      var notify = new Notification(title, {
        dir: 'auto',
        lang: 'zh-CN',
        requireInteraction: true,
        data: options.data,
        tag: tag,//实例化的notification的id
        icon: options.icon,//通知的缩略图,//icon 支持ico、png、jpg、jpeg格式
        body: options.body //通知的具体内容
      });
      notify.onclick = function (e) {
        //如果通知消息被点击,通知窗口将被激活
        window.focus();
        notify.close();
        //打开对应的界面
        // window.open(notify.data.url, '_blank');
        options.onclick  && options.onclick(notify);
        console.log('点击对应的消息通知：', e);
      };
      notify.onerror = function () {
        console.log("消息出错！！！");
      };
      notify.onclose = function () {
        console.log("消息关闭！！！");
      };
    });
  } else {
    console.log("您的浏览器不支持桌面消息");
  }
};