import axios from 'axios';
import {useUserStoreHook} from '@/store/modules/user'
import {ElMessage} from 'element-plus'
import {localStorage} from "@/utils/storage";
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    // baseURL: import.meta.env.VITE_APP_BASE_API_OLD || '/',
    baseURL: '/',
    timeout: 50000,
    headers: {'Content-Type': 'application/json;charset=utf-8'},

    withCredentials: true,
    responseType: 'json',
});



// 请求拦截器
service.interceptors.request.use(
    config => {
        if (!config?.headers) {
          throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
        }

        if (useUserStoreHook().token) {
          // authorization
          // operatorId
            config.headers.Authorization = `${localStorage.get('token')}`;
        }
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);


// 响应拦截器
service.interceptors.response.use(
    response => {
          // 对响应数据做点什么
          const data = response.data.data || {};
          const {code, msg} = data;
        if (response.status === 200) {
            if (response.data || data.access_token) {
              return data;
            } else {
              console.log(`系统出错o0:${response.request.responseURL}`);
              console.log(`response:`, response);
              const msgTips = msg || `系统出错o1: ${data.error} ${data.error_description} ${response.request.responseURL}`;
              if(response.config.url == '/auth/oauth/token'){
                ElMessage({
                  message: `登录失败：${ data.error_description }`,
                  type: 'error'
                })
            
                return Promise.reject(new Error(msgTips || 'Error'))
              }

              
                ElMessage({
                    message: msgTips,
                    type: 'error'
                })
            
                return Promise.reject(new Error(msgTips || 'Error'))
            }
            // return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        if(!error.response || !error.response.data){
          
          ElMessage({
            message:  `系统出错-:${error.message}`,
            type: 'error'
          })
          return ;
        }

        const response = error.response;
        const {code, msg} = error.response.data
        if (code === 'A0230') {  // token 过期
            localStorage.clear(); // 清除浏览器全部缓存
            window.location.href = '/'; // 跳转登录页
            ElMessageBox.alert('当前页面已失效，请重新登录', '提示', {})
                .then(() => {
                })
                .catch((e) => {
                  console.log(e);
                });
        } else {
            console.log(`系统出错2:${response.request.responseURL}`);
            console.log(`response:`, response);
            ElMessage({
                message: msg || `系统出错2:${response.request.responseURL}`,
                type: 'error'
            })
        }
        return Promise.reject(new Error(msg || 'Error'))
    }
);

// 导出 axios 实例
export default service;
