import axios from 'axios';
import { useUserStoreHook } from '@/store/modules/user'
import { ElMessage } from 'element-plus'
import { localStorage } from "@/utils/storage";

// axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  // easy-mock服务挂了，暂时不使用了
  // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
  baseURL: import.meta.env.VITE_APP_BASE_API || '/',
  timeout: 50000,
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
  // headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
  withCredentials: true,
  // responseType: 'json',
  //   transformRequest: [function(data) {

  //     let ret = ''
  //     for(let it in data) {
  //         ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  //     }
  //      return ret
  //  }],
});



// 请求拦截器
service.interceptors.request.use(
  config => {
    if (!config?.headers) {
      throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
    }

    if (~config.headers['Content-Type'].indexOf('application/x-www-form-urlencoded')) {
      const data = config.data;
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      config.data = ret
    }

    if (useUserStoreHook().token) {
      config.headers.Authorization = useUserStoreHook().token;
      config.headers.operatorId = useUserStoreHook().adminId;
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);


const ERROR_TYPES = {
  '400': '请求错误',
  '401': '未授权，请登录',
  '403': '拒绝访问',  // 无权限
  '404': '请求地址出错',
  '405': '请求方法错误',
  '408': '请求超时',
  '500': '服务器内部错误',
  '501': '服务未实现',
  '502': '网关错误',
  '503': '服务不可用',
  '504': '网关超时',
  '505': 'HTTP版本不受支持',
  'Bad credentials': '用户名或密码错误',
}

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    const data = response.data || {};

    if (typeof data == "string" || data instanceof ArrayBuffer) {
      return data;
    }

    const { code, msg } = data;

    if (response.status === 200) {
      if (code === 0 || data.access_token) {
        return data;
      } else if (response.config.url == '/api-web/v1/order/new/amount') {
        return data;
      } else if (data.error === 'invalid_token') {  // token 过期
        localStorage.removeItem("token");
        localStorage.clear(); // 清除浏览器全部缓存

        ElMessageBox.alert('当前页面已失效，请重新登录', '提示', {})
          .then(() => {
            window.location.reload();
          })
          .catch((e) => {
            console.log(e);
          });
        return Promise.reject(response);
      }
      return Promise.reject(response);
    }
    return Promise.reject(response);

  },
  (error) => {

    // console.log(`系统出错0:`, error);
    // console.log(`response:`, response);
    if (!error.response || !error.response.data) {

      ElMessage({
        message: `系统出错-:${error.message}`,
        type: 'error'
      })
      return;
    }

    const response = error.response || {};
    const data = response.data || {};

    const { code, msg } = data;

    console.log(`系统出错2: ${code} ${msg} ${response.request.responseURL}`);
    console.log(`response:`, response);
    console.log(`data:`, data);
    ElMessage({
      message: msg || data.error || `系统出错2: ${code || data.error} ${response.request.responseURL}`,
      type: 'error'
    })

    return Promise.reject(new Error(msg || 'Error'))
  }
);

// 导出 axios 实例
export default service;
