<template>
  <!-- 递归子菜单 -->
  <template v-for="(item, index) of subs" :key="index">
    <template v-if="item.subs">
      <el-submenu :index="item.index" :key="index">
        <template #title>
          <i class="menu-icon" :class="item.icon"></i>
          <span>{{ item.title }}</span>
        </template>
        <sub-menu-item :subs="item.subs"> </sub-menu-item>
      </el-submenu>
    </template>
    <template v-else>
      <el-menu-item :index="item.url || item.index" :key="index">
        <!-- :route="{path: item.index || item.url}" -->
        <i class="menu-icon" :class="item.icon"></i>
        <template #title>{{ item.title}}</template>
      </el-menu-item>
    </template>

  </template>
</template>


<script>
import {toRefs, reactive} from "vue";

export default {
   name: 'sub-menu-item',
   props: {
    subs: {
      type: Array,
      default: []
    },
  },
  components: {},
  // emits: ['click'],
   setup(props, ctx) {

     // subs
    const state = reactive({
      subs: props.subs || []
    })
    // const onClickHandle = (e)=>{
    //   ctx.emit('click', e)
    // }
    return {
      ...toRefs(state),
      // onClickHandle
    };
  },
};
</script>

<style scoped>
.menu-icon{
  width: 24px;
  height: 17px;
  display: inline-block;
}
.pagination-container {
  background: #fff;
  padding: 32px 16px;
}

.pagination-container.hidden {
  display: none;
}
</style>
