import router from "@/router";
import {ElMessage} from "element-plus";
import { usePermissionStoreHook } from "@/store/modules/permission";
import { useUserStoreHook } from "@/store/modules/user";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
NProgress.configure({showSpinner: false}) // 进度环显示/隐藏

// 白名单
const whiteList = ['/login', '/auth-redirect']

router.beforeEach(async (to, form, next) => {
    NProgress.start()

    const hasToken =useUserStoreHook().token
    if (hasToken) {
        // 如果登录成功，跳转到首页
        if (to.path === '/login') {
            next({path: '/'})
            NProgress.done()
        } else {
            const roles = useUserStoreHook().roles
            const hasGetUserInfo = roles.length > 0
            if (hasGetUserInfo) {
             
              if(to.meta.roles){
                let result = false;
                for(const role of to.meta.roles){
                    if(roles.includes(role)){
                        result = true;
                        break;
                    }
                }

                if(!result){
                  next({path: '/403'})
                }else{
                  next()
                }
             
              }else{
                next()
              }
  
                
            } else {
                try {
                    await useUserStoreHook().getUserInfo()
                    // const roles =useUserStoreHook().roles
                    // const accessRoutes = await usePermissionStoreHook().generateRoutes(roles)
                    // accessRoutes.forEach((route) => {
                    //     router.addRoute(route)
                    // })
                    next({...to, replace: true})
                } catch (error) {
                  console.log(error);
                    // remove token and go to login page to re-login
                    await useUserStoreHook().resetToken()
                    // ElMessage.error(JSON.stringify(error) || 'Has Error')
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
