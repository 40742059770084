import request from '@/utils/request'
import requestOld from '@/utils/requestOld'



/**
 * 获取当前是否有新订单
 * @param {*} params.loginTime  登录时间
 * @param {*} params.createTime  调用接口时候的时间戳`
 */
export function orderNewAmount (queryParams={}) {
  return request({
    url: '/api-web/v1/order/new/amount',
    method: 'post',
    data: queryParams
  })
}


/**
 * 获取订单分页列表
 *
 * orderStatus	number	非必须		订单状态 -1:已取消 1:已下单 2:待预约 3:待服务 4:服务完成 5:订单完成 6:已关闭	
    payStatus	number	非必须		支付状态 1:待付定金 2:已付定金 3:已付尾款 4:已退定金 5:未退定金	
    type	number	非必须		1:订单号 2:预约手机 3:预约人 4:清洗点 5:渠道 6:代理	
    keyword	string	非必须		type为1、2、3传keyword	
    keywords	string	非必须		type为4、5、6传keywords	
    createStart	number	非必须		下单开始时间，当日0点时间戳	
    createEnd	number	非必须		下单结束时间，当日0点时间戳	
    appointStart	number	非必须		预约开始时间，当日0点时间戳	
    appointEnd	number	非必须		预约结束时间，当日0点时间戳
 * @param queryParams
 */
export function listOrdersWithPage (queryParams) {
  return request({
    url: '/api-web/v1/order/list',
    method: 'post',
    data: queryParams
  })
}


/**
 * 
 * 修改时间
 *orderId	number	必须			
appointmentTime	string	必须		第一次预约时间 0点时间戳	
secondAppointmentTime	string	必须		第二次预约时间
 * @param queryParams
 */
export function appointTime (queryParams) {
  return request({
    url: '/api-web/v1/order/appoint/time',
    method: 'post',
    data: queryParams
  })
}



/**
 * 
 * 下单失败
 *orderId	number	必须			
appointmentTime	string	必须		第一次预约时间 0点时间戳	
secondAppointmentTime	string	必须		第二次预约时间
 * @param queryParams
 */
export function failList (queryParams) {
  return request({
    url: '/api-web/v1/order/fail/list',
    method: 'post',
    data: queryParams
  })
}


/**
 * 
 * 取消订单
 *orderId	string	必须
 * @param queryParams
 */
export function orderCancel (queryParams) {
  return request({
    url: '/api-web/v1/order/cancel',
    method: 'post',
    data: queryParams
  })
}
/**
 * 
 * 修改清洗点备注
 *orderId	string	必须
 * @param queryParams
 */
export function editShopRemark (queryParams) {
  return request({
    url: '/api-web/v1/order/update/shopRemark',
    method: 'post',
    data: queryParams
  })
}
/**
 * 
 * 清洗点是否饱和
 *orderId	string	必须
 * @param queryParams
 */
export function editIsStatus (queryParams) {
  return request({
    url: '/api-web/v1/company/isSaturate',
    method: 'post',
    data: queryParams
  })
}
/**
 * 
 * 关闭订单：不退定金
 *orderId	string	必须
 * @param queryParams
 */
export function orderClose (queryParams) {
  return request({
    url: '/api-web/v1/order/close',
    method: 'post',
    data: queryParams
  })
}
/**
 * 
 * 关闭订单:退订金
 *orderId	string	必须
 * @param queryParams
 */
export function orderPayClose (queryParams) {
  return request({
    url: '/api-web/v1/pay/refund',
    method: 'post',
    data: queryParams
  })
}

/**
 * 
 * 完成订单
 *orderId	string	必须
 * @param queryParams
 */
export function serviceComplete (queryParams) {
  return request({
    url: '/api-web/v1/order/service/complete',
    method: 'post',
    data: queryParams
  })
}


/**
 * 获取订单详情列表
*orderId	number	必须	
 * @param queryParams
 */
export function ordersInfo (queryParams) {
  return request({
    url: '/api-web/v1/order/detail',
    method: 'post',
    data: queryParams
  })
}


/**
 * 获取订单详情
 *
 * @param orderId
 */
export function getOrderDetail (orderId) {
  return requestOld({
    url: '/mall-oms/api/v1/orders/' + orderId,
    method: 'get'
  })
}

// 导出订单列表
 export function reqExportOrder (data) {
  return request({
    url: '/api-web/v1/export/order',
    method: 'post',
    responseType: 'arraybuffer', // blob
    data: data || {}
  })
}



