import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

export const Layout = () => import('@/layout/index.vue')

export const constantRoutes = [
  {
    path: '/',
    redirect: '/dashboard'
  }, {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: '系统首页',
          icon: 'dashboard',
          affix: true,
          // activeMenu:'/table',
          // roles: ['ADMIN', 'proxy']
        },
        component: () => import( /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
      },
      {
        path: "/orderlist",
        name: "orderlist",
        meta: {
          title: '订单列表'
        },
        component: () => import( /* webpackChunkName: "donate" */ "../views/order/index.vue")
      },
      {

        path: "/orderlistshop",
        name: "orderlistshop",
        meta: {
          title: '订单列表-清洗点',
          activeMenu: '/shoplist'
        },
        component: () => import("../views/order/OrderListClean.vue")
      },
      {

        path: "/channelorderlist",
        name: "channelorderlist",
        meta: {
          title: '订单列表-渠道',
          activeMenu: '/channellist'
        },
        component: () => import("../views/order/OrderListChannel.vue")
      },
      {
        path: "/proxyorder",
        name: "proxyorder",
        meta: {
          title: '订单列表-代理',
          activeMenu: '/proxylist'
        },
        component: () => import( /* webpackChunkName: "donate" */ "../views/order/OrderListProxy.vue")
      },
      {

        path: "/orderfailed",
        name: "orderfailed",
        meta: {
          title: '超出范围列表'
        },
        component: () => import( /* webpackChunkName: "donate" */ "../views/order/OrderFailed.vue")
      },
      {
        path: "/calendar",
        name: "calendar",
        meta: {
          activeMenu: '/orderlist',
          title: '订单详情'
        },
        component: () => import( /* webpackChunkName: "donate" */ "../views/order/Calendar.vue")
      },
      {
        path: "/productlist",
        name: "productlist",
        meta: {
          title: '商品列表'
        },
        component: () => import( /* webpackChunkName: "tabs" */ "../views/product/ProductList.vue")
      },
      {
        path: "/addproduct",
        name: "addproduct",
        meta: {
          title: '新增商品'
        },
        component: () => import( /* webpackChunkName: "form" */ "../views/product/AddForm.vue")
      },
      {
        path: "/editproduct",
        name: "editproduct",
        meta: {
          activeMenu: '/productList',
          title: '编辑商品'
        },
        component: () => import( /* webpackChunkName: "form" */ "../views/product/EditForm_bak.vue")
      },
      {
        path: "/category",
        name: "category",
        meta: {
          title: '类目管理'
        },
        component: () => import( /* webpackChunkName: "tabs" */ "../views/product/ProductCategory.vue")
      },

      {
        // path: "/proxycustomer",
        // name: "proxycustomer",
        path: "/customerlist",
        name: "customerlist",
        meta: {
          title: '客户列表-客户管理'
        },
        component: () => import("../views/customer/CustomerList.vue")
      },
      {

        path: "/customerdetails",
        name: "customerdetails",
        meta: {
          title: '客户详情-客户管理',
          activeMenu: '/customerlist'
        },
        component: () => import("../views/customer/CustomerDetails.vue")
      },

      {
        path: "/proxylist",
        name: "proxylist",
        meta: {
          title: '代理列表-客户管理'
        },
        component: () => import("../views/customer/ProxyList.vue")
      },


      {

        path: "/channellist",
        name: "channellist",
        meta: {
          title: '渠道列表-客户管理',
        },
        component: () => import("../views/customer/ChannelList.vue")
      },


      {

        path: "/shoplist",
        name: "shoplist",
        meta: {
          title: '清洗点列表-客户管理',
        },
        component: () => import("../views/customer/CleanList.vue")
      },

      {

        path: "/cleaninfo",
        name: "cleaninfo",
        meta: {
          title: '清洗点详情-客户管理',
          activeMenu: '/cleanlist'
        },
        component: () => import("../views/customer/CleanInfo.vue")
      },


      {
        path: "/financesettlementlist",
        name: "financesettlementlist",
        meta: {
          title: '结算单列表-财务'
        },
        component: () => import("../views/Finance/FinanceList.vue")
      },
      {
        path: "/settlementlist",
        name: "settlementlist",
        meta: {
          title: '结算单列表-代理'
        },
        component: () => import("../views/Finance/FinanceListDelegate.vue")
      },
      {
        path: "/settlementlistchannel",
        name: "settlementlistchannel",
        meta: {
          title: '结算单列表-渠道'
        },
        component: () => import("../views/Finance/FinanceListDelegate1.vue")
      },


      {
        path: "/paymentlist",
        name: "paymentlist",
        meta: {
          title: '支付流水'
        },
        component: () => import("../views/Finance/PaymentFlow.vue")
      },

      {
        path: "/settlementInfo",
        name: "settlementInfo",
        meta: {
          title: '结算单详情',
          activeMenu: "/financesettlementlist",
        },
        component: () => import("../views/Finance/FinanceShow.vue")
      },

      {
        path: "/financesettlementdetails",
        name: "financesettlementdetails",
        meta: {
          activeMenu: '/financesettlementlist',
          title: '财务结算单详情'
        },
        component: () => import( /* webpackChunkName: "form" */ "../views/Finance/FinanceShow.vue")
      },


      {
        path: "/accountlist",
        name: "accountlist",
        meta: {
          title: '账户列表-平台'
        },
        component: () => import("../views/account/AccountListIndex.vue")
      },
      {
        path: "/accountlistproxy",
        name: "accountlistproxy",
        meta: {
          title: '账户列表-代理'
        },
        component: () => import("../views/account/AccountListProxy.vue")
      },
      {
        path: "/accountinfochannel",
        name: "accountinfochannel",
        meta: {
          title: '账户信息-渠道'
        },
        component: () => import("../views/account/AccountInfoChannel.vue")
      },


      {
        path: "/settingsbenefit",
        name: "settingsbenefit",
        meta: {
          title: '分润设置'
        },
        component: () => import("../views/account/ProfitDistributionIndex.vue")
      },
      {
        path: "/benefit",
        name: "benefit",
        meta: {
          title: '分润设置-平台'
        },
        component: () => import("../views/account/ProfitDistribution.vue")
      },
      {
        path: "/benefitproxy",
        name: "benefitproxy",
        meta: {
          title: '分润设置-代理'
        },
        component: () => import("../views/account/ProfitDistributionProxy.vue")
      },

      {
        path: "/benefitchannel",
        name: "benefitchannel",
        meta: {
          title: '分润设置-渠道'
        },
        component: () => import("../views/account/ProfitDistributionChannel.vue")
      },
      {
        path: "/benefitshop",
        name: "benefitshop",
        meta: {
          title: '分润设置-清洗点'
        },
        component: () => import("../views/account/ProfitDistributionClean.vue")
      },


      {
        path: "/settingshome",
        name: "settingshome",
        meta: {
          title: '首页设置'
        },
        component: () => import("../views/Marketing/IndecSet.vue")
      },
      {
        path: "/settings",
        name: "settings",
        meta: {
          title: '官网配置'
        },
        component: () => import("../views/Marketing/Settings.vue")
      },
      {
        path: "/investment",
        name: "investment",
        meta: {
          title: '招商联盟'
        },
        component: () => import("../views/Marketing/Investment.vue")
      },

      {
        path: "/settinglonggraphic",
        name: "settinglonggraphic",
        meta: {
          title: '长图文配置'
        },
        component: () => import("../views/Marketing/ImageTextList.vue")
      },
      {
        path: "/settingarticle",
        name: "settingarticle",
        meta: {
          title: '文章配置'
        },
        component: () => import("../views/Marketing/ArticleList.vue")
      },






      /**************************************************************** */


      {
        path: "/opinionlist",
        name: "opinionlist",
        meta: {
          title: '意见统计'
        },
        component: () => import("../views/statistics/CustomerList.vue")
      },
      {
        path: "/statisticalrevenue",
        name: "statisticalrevenue",
        meta: {
          title: '营收统计'
        },
        component: () => import("../views/statistics/Income.vue")
      }, {
        path: "/statisticalbenefit",
        name: "statisticalbenefit",
        meta: {
          title: '分润统计'
        },
        component: () => import("../views/statistics/Profit.vue")
      }, {
        path: "/statisticaluser",
        name: "statisticaluser",
        meta: {
          title: '用户统计'
        },
        component: () => import("../views/statistics/UserStatistics.vue")
      },
      {
        path: "/accountinfoclean",
        name: "accountinfoclean",
        meta: {
          title: '账户信息-清洗点'
        },
        component: () => import("../views/account/AccountInfoClean.vue")
      },
      {
        path: "/articlelist",
        name: "articlelist",
        meta: {
          title: '文章列表'
        },
        component: () => import("../views/Marketing/ArticleList.vue")
      },
      {
        path: "/editorder",
        name: "editorder",
        meta: {
          title: '修改项目-修改订单',
          activeMenu: '/orderlist',

        },
        component: () => import( /* webpackChunkName: "donate" */ "../views/ModifyProject.vue")
      },
      {
        path: "/table",
        name: "basetable",
        meta: {
          hidden: true,
          title: '表格'
        },
        component: () => import( /* webpackChunkName: "table" */ "../views/BaseTable.vue")
      },
      {
        path: "/charts",
        name: "basecharts",
        meta: {
          title: '图表'
        },
        component: () => import( /* webpackChunkName: "charts" */ "../views/BaseCharts.vue")
      },
      {
        path: "/tabs",
        name: "tabs",
        meta: {
          title: 'tab标签'
        },
        component: () => import( /* webpackChunkName: "tabs" */ "../views/Tabs.vue")
      },

      {
        path: "/permission",
        name: "permission",
        meta: {
          title: '权限管理',
          permission: true
        },
        component: () => import( /* webpackChunkName: "permission" */ "../views/Permission.vue")
      }, {
        path: "/i18n",
        name: "i18n",
        meta: {
          title: '国际化语言'
        },
        component: () => import( /* webpackChunkName: "i18n" */ "../views/I18n.vue")
      }, {
        path: "/upload",
        name: "upload",
        meta: {
          title: '上传插件'
        },
        component: () => import( /* webpackChunkName: "upload" */ "../views/Upload.vue")
      }, {
        path: "/icon",
        name: "icon",
        meta: {
          title: '自定义图标'
        },
        component: () => import( /* webpackChunkName: "icon" */ "../views/Icon.vue")
      }, {
        path: '/404',
        name: '404',
        meta: {
          title: '找不到页面'
        },
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
      }, {
        path: '/403',
        name: '403',
        meta: {
          title: '没有权限'
        },
        component: () => import(/* webpackChunkName: "403" */ '../views/403.vue')
      }, {
        path: '/user',
        name: 'user',
        meta: {
          title: '个人中心'
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')
      }, {
        path: '/editor',
        name: 'editor',
        meta: {
          title: '富文本编辑器'
        },
        component: () => import(/* webpackChunkName: "editor" */ '../views/Editor.vue')
      }
    ]
  }, {
    path: "/login",
    name: "login",
    meta: {
      title: '登录'
    },
    component: () => import( /* webpackChunkName: "login" */ "../views/Login.vue")
  }

];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
});

export function resetRouter () {
  const newRouter = router;
  (router).matcher = (newRouter).matcher
}

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 三邦侠管理后台`;
  next();

  // const role = localStorage.getItem('ms_username');
  // if (!role && to.path !== '/login') {
  //     next('/login');
  // } else if (to.meta.permission) {
  //     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
  //     role === 'admin'
  //         ? next()
  //         : next('/403');
  // } else {
  //     next();
  // }
});

export default router;