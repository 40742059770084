

const defaultSettings = {
    title: '三邦侠',
    showSettings: true,
    tagsView: true,
    fixedHeader: false,
    // 是否显示Logo
    sidebarLogo: true,
    errorLog: 'production'
}

export default defaultSettings