import {defineStore} from "pinia";
import {store} from "@/store";
import defaultSettings from '../../../settings'
import {localStorage} from "@/utils/storage";

const {showSettings, tagsView, fixedHeader, sidebarLogo} = defaultSettings
import variables from '@/styles/element-variables.module.scss'

export const useSettingStore = defineStore({
    id: "setting",
    state: () => ({
        theme: localStorage.get("theme") || variables.theme,
        showSettings: showSettings,
        tagsView: tagsView,
        fixedHeader: fixedHeader,
        sidebarLogo: sidebarLogo,
        collapse: false
    }),
    actions: {
        async changeSetting(payload) {
            const {key, value} = payload
            switch (key) {
                case 'theme':
                    this.theme = value
                    break
                case 'showSettings':
                    this.showSettings = value
                    break
                case 'fixedHeader':
                    this.fixedHeader = value
                    break
                case 'tagsView':
                    this.tagsView = value
                    break
                case 'sidebarLogo':
                    this.sidebarLogo = value
                    break
                default:
                    break
            }
        },
        // 侧边栏折叠
      handleCollapse( data) {
        this.collapse = data;
      },
    }
})

export function useSettingStoreHook() {
    return useSettingStore(store);
}

