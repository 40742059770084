<template>
  <el-config-provider :locale="locale">
    <router-view v-if="isRouterAlive" />
  </el-config-provider>
</template>

<script>
import { computed, onMounted, ref, watch, provide, nextTick } from "vue";
import { useAppStoreHook } from "@/store/modules/app";
import { useUserStoreHook } from "@/store/modules/user";
import { orderNewAmount } from "@/api/oms/order"
// import {ElConfigProvider} from 'element-plus'
import { localStorage } from "@/utils/storage";


//官方文档: https://element-plus.gitee.io/zh-CN/guide/i18n.html

// 导入 Element Plus 语言包
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import { showNotification, requestPermission, getNotification } from "@/utils/notification";
import { USER_TYPE } from '@/config/userType' 

export default {
  setup () {
    // 页面刷新函数
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };

    provide("reload", reload);

    // setTimeout(() => {
    //   showNotification('新订单通知', {
    //     data: '新订单',
    //     icon: '/images/login_icon_sanbangxia.png'
    //   });
    // }, 1000)

   // 

    let lastReqOrderNewTime = 0;

    const updateHandle = ()=>{
      if(useUserStoreHook().token && (useUserStoreHook().type == USER_TYPE.TYPE_1)){

        if(!lastReqOrderNewTime){
          lastReqOrderNewTime = localStorage.get('req_order_new_time');
        }

        const createTime = new Date().getTime();

        orderNewAmount({
          loginTime: lastReqOrderNewTime,
          createTime: createTime
        }).then(res=>{
          lastReqOrderNewTime = createTime;
          localStorage.set('req_order_new_time', lastReqOrderNewTime);

          if(res.data > 0){
            showNotification('新订单通知', {
              data: '新订单',
              icon: '/images/logo.png',
              onclick: function(data){
                console.log('showNotification onclick:', data)
                window.open('/#/orderlist', 'orderlist');
              }
            });
          }

        })
      }
    }

    const initNotificationHandle =  async()=>{
      const isHasPermission = await requestPermission();
      if(isHasPermission){
        setInterval(()=>{
          updateHandle();
        }, 20000)
      }
    }

    const onClickDocumentHandler = ()=>{
      document.removeEventListener('click', onClickDocumentHandler);
      initNotificationHandle();
    }


    const Notification = getNotification();
    // denied granted default
    if(Notification.permission == 'granted'){
      initNotificationHandle();
    } else {
      document.addEventListener('click', onClickDocumentHandler);
    }

   


    return {
      isRouterAlive,
      reload,
    }
    //秒级时间戳
  }
}


const language = computed(() => useAppStoreHook().language)

const locale = ref(zhCn)
watch(language, (value) => {
  console.log('====language===:', value);
  if (value == 'en') {
    locale.value = en
  } else {
    locale.value = zhCn
  }
}, {
  // 初始化立即执行，
  immediate: true
})
onMounted(() => {
  const style = localStorage.get("style");
  document.documentElement.style.cssText = style;
})

</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>
