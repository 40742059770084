<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu"
             :default-active="onRoutes"
             :collapse="collapse"
             background-color="#324157"
             text-color="#bfcbd9"
             active-text-color="#20a0ff"
             unique-opened
             router>
      <!-- router -->
      <sub-menu-item :subs="state.items"></sub-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useSettingStoreHook } from "@/store/modules/settings";
import { useRoute } from "vue-router";
import { getMenuList } from "@/menu"
import { useUserStoreHook } from "@/store/modules/user";
import { ElMessage } from 'element-plus';
import SubMenuItem from "./MenuSide/SubMenuItem.vue"
// import { usePermissionStoreHook } from "@/store/modules/permission";
export default {
  components: {
    'sub-menu-item': SubMenuItem
  },
  setup () {
    const state = reactive({
      items: []
    })
    const roles = useUserStoreHook().roles;

    getMenuList(roles).then((data) => {
      state.items = data;
    }).catch((e) => {
      ElMessage.error(e + 'getMenuList');
      console.error(e);
    })
    const route = useRoute();

    const onRoutes = computed(() => {
      return route.meta && route.meta.activeMenu || route.path;
    });

    const collapse = computed(() => useSettingStoreHook().collapse);

    return {
      state,
      onRoutes,
      collapse,
    };
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 230px;
}
.sidebar > ul {
  height: 100%;
}
</style>
