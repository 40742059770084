import { defineStore } from "pinia";
import { store } from "@/store";

const useTagsViewStore = defineStore({
  id: "tagsView",
  state: () => ({
    tagsList: [],

    // ========================
    visitedViews: [],
    cachedViews: [],


  }),
  actions: {

    delTagsItem (data) {
      this
        .tagsList
        .splice(data.index, 1);
    },
    setTagsItem (data) {
      this
        .tagsList
        .push(data)
    },
    clearTags () {
      this.tagsList = []
    },
    closeTagsOther (data) {
      this.tagsList = data;
    },
    closeCurrentTag (data) {
      const state = this;
      for (let i = 0, len = state.tagsList.length; i < len; i++) {
        const item = state.tagsList[i];
        if (item.path === data.$route.fullPath) {
          if (i < len - 1) {
            data
              .$router
              .push(state.tagsList[i + 1].path);
          } else if (i > 0) {
            data
              .$router
              .push(state.tagsList[i - 1].path);
          } else {
            data
              .$router
              .push("/");
          }
          state
            .tagsList
            .splice(i, 1);
          break;
        }
      }
    },

    // =================================================================
    addVisitedView (view) {
      if (this.visitedViews.some(v => v.path === view.path)) return
      this.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta?.title || 'no-name'
        })
      )
    },
    addCachedView (view) {
      if (this.cachedViews.includes(view.name)) return
      if (!view.meta.noCache) {
        this.cachedViews.push(view.name)
      }
    },

    delVisitedView (view) {
      return new Promise(resolve => {
        for (const [i, v] of this.visitedViews.entries()) {
          if (v.path === view.path) {
            this.visitedViews.splice(i, 1)
            break
          }
        }
        resolve([...this.visitedViews])
      })

    },
    delCachedView (view) {
      return new Promise(resolve => {
        const index = this.cachedViews.indexOf(view.name)
        index > -1 && this.cachedViews.splice(index, 1)
        resolve([...this.cachedViews])
      })

    },

    delOtherVisitedViews (view) {
      return new Promise(resolve => {
        this.visitedViews = this.visitedViews.filter(v => {
          return v.meta?.affix || v.path === view.path
        })
        resolve([...this.visitedViews])
      })

    },
    delOtherCachedViews (view) {
      return new Promise(resolve => {
        const index = this.cachedViews.indexOf(view.name)
        if (index > -1) {
          this.cachedViews = this.cachedViews.slice(index, index + 1)
        } else {
          // if index = -1, there is no cached tags
          this.cachedViews = []
        }
        resolve([...this.cachedViews])
      })

    },

    updateVisitedView (view) {
      for (let v of this.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view)
          break
        }
      }
    },
    addView (view) {
      this.addVisitedView(view)
      this.addCachedView(view)
    },
    delView (view) {
      return new Promise(resolve => {
        this.delVisitedView(view)
        this.delCachedView(view)
        resolve({
          visitedViews: [...this.visitedViews],
          cachedViews: [...this.cachedViews]
        })
      })
    },
    delOtherViews (view) {
      return new Promise(resolve => {
        this.delOtherVisitedViews(view)
        this.delOtherCachedViews(view)
        resolve({
          visitedViews: [...this.visitedViews],
          cachedViews: [...this.cachedViews]
        })
      })
    },
    delLeftViews (view) {
      return new Promise(resolve => {
        const currIndex = this.visitedViews.findIndex(v => v.path === view.path)
        if (currIndex === -1) {
          return
        }
        this.visitedViews = this.visitedViews.filter((item, index) => {
          // affix:true 固定tag，例如“首页”
          if (index >= currIndex || (item.meta && item.meta.affix)) {
            return true
          }

          const cacheIndex = this.cachedViews.indexOf(item.name)
          if (cacheIndex > -1) {
            this.cachedViews.splice(cacheIndex, 1)
          }
          return false
        })
        resolve({
          visitedViews: [...this.visitedViews]
        })
      })
    },
    delRightViews (view) {
      return new Promise(resolve => {
        const currIndex = this.visitedViews.findIndex(v => v.path === view.path)
        if (currIndex === -1) {
          return
        }
        this.visitedViews = this.visitedViews.filter((item, index) => {
          // affix:true 固定tag，例如“首页”
          if (index <= currIndex || (item.meta && item.meta.affix)) {
            return true
          }

          const cacheIndex = this.cachedViews.indexOf(item.name)
          if (cacheIndex > -1) {
            this.cachedViews.splice(cacheIndex, 1)
          }
          return false
        })
        resolve({
          visitedViews: [...this.visitedViews]
        })
      })
    },
    delAllViews (view) {
      return new Promise(resolve => {
        const affixTags = this.visitedViews.filter(tag => tag.meta?.affix)
        this.visitedViews = affixTags
        this.cachedViews = []
        resolve({
          visitedViews: [...this.visitedViews],
          cachedViews: [...this.cachedViews]
        })
      })
    },
    delAllVisitedViews () {
      return new Promise(resolve => {
        const affixTags = this.visitedViews.filter(tag => tag.meta?.affix)
        this.visitedViews = affixTags
        resolve([...this.visitedViews])
      })
    },
    delAllCachedViews () {
      return new Promise(resolve => {
        this.cachedViews = []
        resolve([...this.cachedViews])
      })
    },
  }
})


export function useTagsViewStoreHook () {
  return useTagsViewStore(store);
}

