<template>
  <div class="about">
    <v-header />
    <v-sidebar />
    <div class="content-box"
         :class="{ 'content-collapse': collapse }">
      <v-tags></v-tags>
      <div class="content">
        <router-view v-slot="{ Component }" :key="routePath">
          <transition name="move"
                      mode="out-in">
            <keep-alive :include="tagsList">
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
        <!-- <el-backtop target=".content"></el-backtop> -->
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import vHeader from "../components/Header.vue";
import vSidebar from "../components/Sidebar.vue";
import vTags from "../components/Tags.vue";
import { useSettingStoreHook } from "@/store/modules/settings";
import { useTagsViewStoreHook } from "@/store/modules/tagsView";
import { useRoute, useRouter } from "vue-router";
export default {
  components: {
    vHeader,
    vSidebar,
    vTags,
  },
  setup () {

    const route = useRoute();

    const routePath = computed(() =>
      route.fullPath
    );

    const tagsList = computed(() =>
      useTagsViewStoreHook().tagsList.map((item) => item.name)
    );
    const collapse = computed(() => useSettingStoreHook().collapse);
    return {
      routePath,
      tagsList,
      collapse,
    };
  },
};
</script>
<style scoped>
.width100 {
  width: 100%;
}
</style>
