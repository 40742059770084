import { reqMenuList } from '@/api/system/menu'
import { useUserStoreHook } from '@/store/modules/user';

const menuItems = [
  {
    icon: "el-icon-lx-home",
    index: "/dashboard",
    title: "系统首页",
  },
  {
    icon: "el-icon-lx-calendar",
    index: "/order",
    title: "订单管理",
    subs: [
      {
        index: "/orderlist",
        title: "订单列表",
        // roles: ['ADMIN']
      },
      {
        index: "/orderfailed",
        title: "超出范围列表",
      }
    ],
  }, {
    icon: "el-icon-lx-calendar",
    index: "product",
    title: "商品管理",
    subs: [{
      index: "/productlist",
      title: "商品列表",
    },
    {
      index: "/addproduct",
      title: "新增商品",
      // roles: ['ADMIN']
    },
    {
      index: "/category",
      title: "类目管理",
      // roles: ['ADMIN']
    }

    ],
  },
  {
    icon: "el-icon-lx-calendar",
    // index: "2",
    title: "财务管理",
    index: "/finance",
    subs: [{
      index: "/financesettlementlist",
      title: "结算单列表-财务",
    }, {
      index: "/FinanceListDelegate",
      title: "结算单列表-代理",
    },
    {
      index: "/paymentlist",
      title: "支付流水",
      // roles: ['ADMIN']
    }

    ],
  },
  {
    icon: "el-icon-lx-settings",
    title: "系统设置",
    index: "/settings",
    subs: [
      {
        index: "/accountlist",
        title: "账户列表-平台",
      },
      {
        index: "/benefit",
        title: "分润设置-平台",
      },
      {
        index: "/benefitproxy",
        title: "分润设置-代理",
      },
      {
        index: "/benefitchannel",
        title: "分润设置-渠道",
      },
      {
        index: "/benefitshop",
        title: "分润设置-清洗点",
      }
    ],
  },

  {
    icon: "el-icon-lx-settings",
    title: "账户管理",
    index: "/account",
    subs: [
      {
        index: "/accountlist",
        title: "账户列表-平台",
      },
      {
        index: "/accountlistproxy",
        title: "账户列表-代理",
      },
      {
        index: "/accountinfochannel",
        title: "账户信息-渠道",
      },
      {
        index: "/accountinfoclean",
        title: "账户信息-清洗点",
      }
    ],
  },
  {
    icon: "el-icon-lx-settings",
    title: "营销管理",
    // index: "/Marketing",
    subs: [
      {
        index: "/settingshome",
        title: "首页设置",
      },
      {
        index: "/articlelist",
        title: "文章列表",
      },
      {
        index: "/settinglonggraphic",
        title: "长图文列表",
      },
      {
        index: "/settings",
        title: "官网配置",
      },
    ],
  },
  {
    icon: "el-icon-lx-settings",
    title: "客户管理",
    index: "/customer",
    subs: [
      {
        index: "/customerlist",
        title: "客户列表",
      },
      {
        index: "/proxylist",
        title: "代理列表",
      },
      {
        index: "/channellist",
        title: "渠道列表",
      },
      {
        index: "/shoplist",
        title: "清洗点列表",
      }
    ],
  }
  , {
    icon: "el-icon-lx-settings",
    title: "统计管理",
    index: "/statistics",
    subs: [
      {
        index: "/incomestatistics",
        title: "营收统计",
      },
      {
        index: "/profitstatistics",
        title: "分润统计",
      },
      {
        index: "/userstatistics",
        title: "用户统计",
      },
    ],
  }
  ,
  {
    icon: "el-icon-lx-cascades",
    index: "/table",
    title: "基础表格",
  },
  {
    icon: "el-icon-lx-copy",
    index: "/tabs",
    title: "tab选项卡",
  },
  {
    icon: "el-icon-lx-calendar",
    index: "3",
    title: "表单相关",
    subs: [
      // {
      //   index: "/form",
      //   title: "基本表单",
      // },
      {
        index: "/upload",
        title: "文件上传",
      },
      {
        index: "4",
        title: "三级菜单",
        subs: [
          {
            index: "/editor",
            title: "富文本编辑器",
          },
        ],
      },
    ],
  },
  {
    icon: "el-icon-lx-emoji",
    index: "/icon",
    title: "自定义图标",
  },
  {
    icon: "el-icon-pie-chart",
    index: "/charts",
    title: "schart图表",
  },
  {
    icon: "el-icon-lx-global",
    index: "/i18n",
    title: "国际化功能",
  },
  {
    icon: "el-icon-lx-warn",
    index: "7",
    title: "错误处理",
    subs: [
      {
        index: "/permission",
        title: "权限测试",
      },
      {
        index: "/404",
        title: "404页面",
      },
    ],
  },
  // {
  // icon: "el-icon-lx-redpacket_fill",
  // index: "/donate",
  // title: "支持作者",
  // },
];


const hasPermission = (roles = [], item) => {

  if (!item) {
    return false;
  }
  if (!item.roles) {
    return true;
  }
  if (roles.includes('ROOT')) {
    return true
  }
  return roles.some(role => {
    return item.roles.includes(role);
  })
}

const filterMenu = (items, roles) => {
  const res = []
  items.forEach(item => {
    const tmp = { ...item }
    if (hasPermission(roles, tmp)) {
      res.push(tmp)
      if (tmp.subs) {
        tmp.subs = filterMenu(tmp.subs, roles)
      }
    }
  })
  return res
}

const transformMenuListData = (dataList, newDataList = []) => {
  if (!dataList) {
    return null;
  }

  /**
   *   {
        icon: "el-icon-lx-calendar",
        index: "2",
        title: "订单管理",
        subs: [
          {
            index: "/order",
            title: "订单列表",
            // roles: ['ADMIN']
          },
          {
            index: "/orderFailed",
            title: "下单失败列表",
          }
        ],
      }
   */
  dataList.forEach((item) => {
    const newList = transformMenuListData(item.children);
    const newItem = {
      title: item.menuName,
      // roles: ['ADMIN']
      menuName: item.menuName,
      index: item.menuId,
      menuId: item.menuId,
      parentId: item.parentId,
      sort: item.sort,
      type: item.type
    }

    if (newList) {
      newItem.subs = newList;
    }

    if (item.icon) {
      newItem.icon = item.icon;
    }

    if (item.url) {
      newItem.url = item.url.trim();
    }

    newDataList.push(newItem);
  })


  return newDataList
}

let resMenuList = [];

export const getMenuList = async (roles) => {

  if (resMenuList && resMenuList.length) {
    return resMenuList;
  }


  const res = await reqMenuList({
    roleId: useUserStoreHook().roleId
  });


  // roles.includes('admin') || roles.includes('ROOT')
  if (roles.includes('ROOT')) {
    // resMenuList = filterMenu(transformMenuListData(res.data), roles)
    resMenuList = filterMenu(menuItems, roles);
    return resMenuList;
  }

  const homePageConfig = res.data.find((item) => {
    return item.url === '/dashboard';
  })

  if (!homePageConfig) {
    res.data.unshift({
      icon: "el-icon-lx-home",
      menuId: 0,
      menuName: "系统首页",
      parentId: 0,
      sort: 0,
      type: 0,
      url: "/dashboard"
    });
  }


  // if (roles.includes('admin') || roles.includes('ROOT')) {
  //   resMenuList = filterMenu(menuItems, roles);
  //   return resMenuList;
  // }

  resMenuList = filterMenu(transformMenuListData(res.data), roles)
  console.log('resMenuList::::', resMenuList)
  return resMenuList;

  resMenuList = filterMenu(menuItems, roles);
  return resMenuList;






  return resMenuList;







};
export default getMenuList
