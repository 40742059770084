import { createApp } from 'vue'
import App from './App.vue'
import Pagination from '@/components/Pagination/index.vue'

import router from './router'
import store from './store'
import '@/permission'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'

const app = createApp(App)
installElementPlus(app)
app.component('Pagination', Pagination)
  .use(store)
  .use(router)
  .mount('#app')