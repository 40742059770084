import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import { localStorage } from "@/utils/storage";
import i18n from "@/lang/index";
import zhCn from 'element-plus/es/locale/lang/zh-cn'

export default (app) => {


  // app.use(ElementPlus, {
  //   locale: zhCn,
  // })
  app.use(ElementPlus, { size: localStorage.get('size') || 'small', locale: zhCn, })
  app.use(i18n)
}
