// import {RouteRecordRaw} from 'vue-router'
import {constantRoutes} from '@/router'
import {listRoutes} from "@/api/system/menu";
import {defineStore} from "pinia";
import {store} from "@/store";

const modules = import.meta.glob("../../views/**/**.vue");
export const Layout = () => import( '../../layout/index.vue')

const hasPermission = (roles, route) => {
    
    if (route.meta && route.meta.roles) {
        if (roles.includes('ROOT')) {
            return true
        }
        return roles.some(role => {
            if (route.meta?.roles !== undefined) {
                return (route.meta.roles).includes(role);
            }
        })
    }
    return false
}

export const filterAsyncRoutes = (routes, roles) => {
    const res = []
    routes.forEach(route => {
        const tmp = {...route}
        if (hasPermission(roles, tmp)) {
            if (tmp.component == 'Layout') {
                tmp.component = Layout
            } else {
                const component = modules[`../../views/${tmp.component}.vue`];
                if (component) {
                    tmp.component = modules[`../../views/${tmp.component}.vue`];
                } else {
                    tmp.component = modules[`../../views/error-page/404.vue`];
                }
            }
            res.push(tmp)

            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles)
            }
        }
    })
    return res
}


export const usePermissionStore = defineStore({
    id: "permission",
    state: () => ({
        routes: [],
        addRoutes: []
    }),
    actions: {
        setRoutes(routes) {
            this.addRoutes = routes
            this.routes = constantRoutes.concat(routes)
            // console.log('routes::', this.routes)
            // console.log('constantRoutes::', constantRoutes)
        },
        generateRoutes(roles) {

          return new Promise((resolve, reject) => {
              listRoutes().then(response => {
                  const asyncRoutes = response.data
                  // console.log('asyncRoutes::', asyncRoutes)
                  // console.log('roles::', roles)
                  let accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
           
                  this.setRoutes(accessedRoutes)
                  // console.log('accessedRoutes::', accessedRoutes)
                  resolve(accessedRoutes)
              }).catch(error => {
                  console.log(error);
                  reject(error)
              })
          })
        }
    }
})

export function usePermissionStoreHook() {
    return usePermissionStore(store);
}
